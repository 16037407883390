import CallForm from '.';
import styled from 'styled-components';
import {BREAKPOINT} from 'aac-components/utils/styles';

const CallFormCta = () => {
    return (
        <div className="cta">
            <div className="cta__title">Questions about treatment? We'll call you.</div>
            <div className="cta__form">
                <CallFormStyles>
                    <CallForm />
                </CallFormStyles>
            </div>
            <style jsx>
                {`
                    .cta {
                        background: var(--secondary-100);
                        padding: 40px 16px;
                        border-radius: 8px;
                        border: 1px solid var(--secondary-200);
                        color: var(--gray-500);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta {
                            padding: 40px;
                        }
                    }
                    .cta__title {
                        font-size: 22px;
                        font-weight: bold;
                        margin-bottom: 30px;
                    }
                `}
            </style>
        </div>
    );
};
export default CallFormCta;

const CallFormStyles = styled.div`
    input {
        border: 1px solid var(--secondary-200);
    }
    .call-form__inputs {
        display: grid;
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .call-form__inputs {
            grid-template-columns: 4fr 1fr;
            align-items: flex-end;
            grid-gap: 8px;
        }
    }
`;
