import Tooltip from 'aac-components/components/Tooltip';
import IconInfo from 'aac-components/components/Icons/Attention/IconInfo';
import {useContext, useEffect, useState} from 'react';
import AppContext from '../AppContext';
import Link from 'next/link';
import {useRouter} from 'next/router';

const AcfTooltip = ({
    hideText = false,
    type = 'phone',
    color = 'var(--gray-500)',
    iconFill = 'var(--interactive-200)',
}) => {
    const {
        acfOptions: {acf: {[`${type}_tooltip`]: {label = '', copy = ''} = {}} = {}} = {},
    } = useContext(AppContext);

    const {asPath} = useRouter();
    const [localRep, setLocalRep] = useState(null);

    useEffect(() => {
        const timmer = setTimeout(() => {
            if (type === 'phone' && window && window.sessionStorage?.getItem('bd_rep')) {
                const rep = JSON?.parse(window.sessionStorage.getItem('bd_rep'));
                setLocalRep(rep);
            }
        }, 2000); // wait a couple secdonds so that if you're on the bd page, it has time to set the storage value
        return () => clearTimeout(timmer);
    }, [asPath]);

    return (
        <div className="tooltip__call">
            {!hideText && <span className="tooltip__text">{label}</span>}

            <Tooltip
                distance={0}
                tagName="div"
                tooltipContent={
                    <div className="tooltip__inner">
                        {localRep ? (
                            <>
                                {asPath.includes(`/local-contact/${localRep?.slug}`) ? (
                                    <>Your local coordinator, {localRep?.title}.</>
                                ) : (
                                    <>
                                        {' '}
                                        Call your local coordinator,{' '}
                                        <Link
                                            href={`/local-contact/${localRep?.slug}`}
                                            title={localRep?.title}>
                                            {localRep?.title}
                                        </Link>
                                        .
                                    </>
                                )}
                            </>
                        ) : (
                            <div
                                className="tooltip__copy"
                                dangerouslySetInnerHTML={{__html: copy}}
                            />
                        )}
                    </div>
                }
                renderChildren={() => (
                    <div className="tooltip__icon">
                        <IconInfo width="18" height="18" fill={iconFill} />
                    </div>
                )}
            />

            <style jsx>
                {`
                    .tooltip__text {
                        color: ${color};
                    }
                    .tooltip__call {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .tooltip__call > span {
                        font-size: 12px;
                        font-weight: bold;
                    }
                    .tooltip__inner {
                        font-size: 12px;
                        max-width: 200px;
                    }
                    .tooltip__list > li {
                        text-align: left;
                        font-size: 12px;
                        margin: 0;
                    }
                    .tooltip__icon {
                        display: flex;
                        align-items: center;
                        margin-left: 5px;
                    }
                `}
            </style>
        </div>
    );
};
export default AcfTooltip;
