import Tooltip from 'aac-components/components/Tooltip/index';
import IconInfo from 'aac-components/components/Icons/Attention/IconInfo';
import React, {useRef} from 'react';

const TooltipIcon = React.forwardRef((props, ref) => (
    <span ref={ref}>
        <IconInfo
            fill={props.visible ? 'var(--interactive)' : 'var(--interactive-200)'}
        />
        <style jsx>{`
            :global(svg) {
                margin-bottom: -5px;
            }
        `}</style>
    </span>
));

const TooltipShortcode = ({text, link, linkText, children}) => {
    const iconRef = useRef();

    return (
        <>
            <span
                className="tooltip-text"
                dangerouslySetInnerHTML={{__html: children}}
                onClick={() => {
                    iconRef?.current?.click();
                }}
            />
            <Tooltip
                tagName="span"
                renderChildren={(visible) => (
                    <TooltipIcon ref={iconRef} visible={visible} />
                )}
                tooltipContent={
                    <>
                        <div
                            className="text-sm"
                            dangerouslySetInnerHTML={{__html: text}}
                        />
                        {link && linkText ? (
                            <a
                                href={link}
                                style={{
                                    display: 'block',
                                    fontWeight: 'bold',
                                    marginTop: 16,
                                    fontSize: '14px',
                                    textDecoration: 'none',
                                }}>
                                {linkText}
                            </a>
                        ) : null}
                    </>
                }
            />
            <style jsx>{`
                :global(.portal_tooltip) {
                    display: inline;
                }
                .tooltip-text {
                    border-bottom: 1px dotted var(--interactive);
                }
                .tooltip-text:hover {
                    cursor: pointer;
                }
            `}</style>
        </>
    );
};

export default TooltipShortcode;
