import RenderOnDesktopOnly from '../RenderOnDesktopOnly';
import RenderOnMobileOnly from '../RenderOnMobileOnly';

import Accordion from 'aac-components/components/Accordion';
import Drawer from './drawer';

const DrawerAccordion = (props) => {
    return (
        <>
            <RenderOnMobileOnly>
                <Drawer {...props} />
            </RenderOnMobileOnly>
            <RenderOnDesktopOnly>
                <Accordion {...props} />
            </RenderOnDesktopOnly>
        </>
    );
};
export default DrawerAccordion;
