import {useForm} from 'react-hook-form';
import React, {useState} from 'react';
import PhoneInput from 'aac-components/components/ReactHookForm/PhoneInput';
import Button from 'aac-components/components/Button';
import styled from 'styled-components';
import {getCookie, getDefaultSalesforceData} from 'aac-components/utils/helpers';
import {getCallTime} from './utils';
import Recaptcha from 'aac-components/components/Recaptcha';
import Accordion from 'aac-components/components/Accordion';
import Link from 'next/link';
import Loading from 'aac-components/components/Svgs/loading';
import IconCheckCircle from 'aac-components/components/Icons/Basic/IconCheckCircle';
import Form from 'aac-components/components/ReactHookForm/Form';

const CallForm = () => {
    const form = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const submit = async (formData) => {
        setIsLoading(true);

        const {Requested_Call_Time_Zone__c = '', Requested_Call_Time__c = ''} =
            await getCallTime();

        // salesforce
        const fetchUrl = `/salesforce/create-lead`;
        const lead_source = 'SCHEDULED-CALL';
        const defaultSalesforceFieldData = getDefaultSalesforceData();

        try {
            const salesforceResponse = await fetch(fetchUrl, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({
                    leadsource: lead_source,
                    firstName: 'john',
                    lastName: 'doe',
                    Requested_Call_Time__c,
                    HasBeenSentSMSConsent__c: true,
                    HasOptedOutOfSMS__c: false,
                    Requested_Call_Time_Zone__c,
                    Call_Me_Now__c: true,
                    Client_Mobile_Phone__c: formData?.phone,
                    description: `Timezone: ${Requested_Call_Time_Zone__c}`,
                    ...formData,
                    ...defaultSalesforceFieldData,
                }),
            });
            const salesforceData = await salesforceResponse.json();
        } catch (e) {
            console.warn(e);
        }

        setIsLoading(false);
        setIsSubmitted(true);
    };

    return (
        <div className="call-form">
            <Form onSubmit={submit} id="call-form" form={form}>
                <>
                    {!isSubmitted ? (
                        <>
                            <div className="call-form__inputs">
                                <PhoneInput
                                    label="Phone"
                                    fieldName="phone"
                                    placeholder="555-555-5555"
                                    required={true}
                                    errorMessage="Phone number is not valid."
                                    {...form}
                                />
                                <ButtonStyles>
                                    <Button theme="primary" type="submit">
                                        {isLoading ? (
                                            <Loading fill="#fff" width="22" height="22" />
                                        ) : (
                                            'Call Me'
                                        )}
                                    </Button>
                                </ButtonStyles>
                            </div>
                        </>
                    ) : (
                        <div className="call-form__submit-message">
                            <div>
                                <IconCheckCircle fill="var(--success)" />
                            </div>
                            <p>
                                An American Addiction Centers admissions navigator will be
                                calling you momentarily.
                            </p>
                        </div>
                    )}
                    <div className="call-form__disclaimers">
                        <AccordionStyles>
                            <Accordion title="Disclaimers">
                                <div>
                                    By submitting this form you agree to the{' '}
                                    <Link
                                        href="/terms-of-use"
                                        title="Terms of Use"
                                        target="_blank">
                                        terms of use
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                        href="/privacy-policy"
                                        title="Privacy Policy"
                                        target="_blank">
                                        privacy policy
                                    </Link>{' '}
                                    of the website. We respect your privacy. We request
                                    this information to provide you with detailed coverage
                                    of benefits. By sharing your phone number, you agree
                                    to receive texts from us – including details about
                                    your benefits. Message and data rates may apply.
                                    Sharing this information is not a condition of
                                    treatment.
                                </div>
                                <RecaptchaStyles>
                                    <Recaptcha />
                                </RecaptchaStyles>
                            </Accordion>
                        </AccordionStyles>
                    </div>
                </>
            </Form>
            <style jsx>
                {`
                    .call-form__disclaimers {
                        margin: 12px 0 0 0;
                    }
                    .call-form__disclaimers p {
                        font-size: 10px;
                        line-height: 16px;
                        margin: 2px 0 8px 0;
                    }
                    .call-form__inputs {
                        pointer-events: ${isLoading ? 'none' : 'auto'};
                        opacity: ${isLoading ? '.5' : '1'};
                    }
                    .call-form__submit-message {
                        display: grid;
                        grid-template-columns: 24px auto;
                        grid-gap: 8px;
                        align-items: center;
                    }
                `}
            </style>
        </div>
    );
};
export default CallForm;

const ButtonStyles = styled.div`
    button {
        width: 100%;
        margin: 16px 0 0 0;
        font-weight: normal;
        border-radius: 4px;
        height: 44px;
        padding: 0;
        display: flex;
        align-items: center;
    }
`;

const RecaptchaStyles = styled.div`
    .recaptcha-links {
        text-align: left !important;
    }
`;

const AccordionStyles = styled.div`
    .accordion {
        border-bottom: none;
    }
    .accordion__head {
        max-width: fit-content;
        padding: 0 10px 0 0;
        border-bottom: none;
    }
    .accordion__title {
        font-size: 12px;
        color: var(--secondary-400);
        margin-right: 12px;
        font-weight: normal;
    }
    .accordion__head:after,
    .accordion__head:before {
        background-color: var(--secondary-400);
        width: 10px;
        height: 2px;
    }
`;
