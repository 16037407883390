import React from 'react';

import AppContext from '../AppContext';

const RenderOnMobileOnly = (props) => {
    const isMobile = props?.context?.isMobile;
    if (!isMobile) return null;
    return <React.Fragment>{props.children}</React.Fragment>;
};
const RenderOnMobileOnlyWithContext = (props) => {
    return (
        <AppContext.Consumer>
            {(context) => <RenderOnMobileOnly context={context} {...props} />}
        </AppContext.Consumer>
    );
};
export default RenderOnMobileOnlyWithContext;
