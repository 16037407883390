export const getCallTime = async () => {
    // dynamic import libraries
    const {format} = (await import('date-fns')).default;

    // just does current time for now, we can add an option for user input later if needed
    const now = new Date();
    const Requested_Call_Time_Zone__c = now
        .toLocaleTimeString('en-us', {timeZoneName: 'short'})
        .split(' ')[2];

    let Requested_Call_Time__c = format(new Date(), `YYYY-MM-DDThh:mm:ss`);

    return {Requested_Call_Time__c, Requested_Call_Time_Zone__c};
};
