import Button from 'aac-components/components/Button';
import styled from 'styled-components';
import Image from 'next/image';
import {BREAKPOINT} from 'aac-components/utils/styles';
import {useContext} from 'react';
import AppContext from '../AppContext';

const VeteranCta = () => {
    const {isMobile} = useContext(AppContext);
    return (
        <div className="veteran-cta">
            <Image
                src="/static/veteran-background.png"
                alt="American Flag"
                fill
                style={{
                    objectFit: `${isMobile ? 'cover' : 'contain'}`,
                    objectPosition: '0',
                }}
                sizes="100vw, 33vw"
            />
            <div className="cta__content">
                <div className="cta__background-blur" />
                <div className="cta__content--inner">
                    <div className="cta__title">
                        Our Specialized Programs for Veterans
                    </div>
                    <div className="cta__copy">
                        Our Veteran program is dedicated to military veterans and first
                        responders whose lives have changed and become unmanageable due to
                        substance use disorder and mental health issues.
                    </div>
                    <div className="cta__button">
                        <ButtonStyles>
                            <Button
                                href="/veterans/specialized-treatment-program"
                                style={{fontWeight: 'normal'}}>
                                Learn about the program
                            </Button>
                        </ButtonStyles>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .veteran-cta {
                        background: var(--secondary-100);
                        text-align: center;
                        border-radius: 8px;
                        padding: 16px;
                        position: relative;
                        overflow: hidden;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .veteran-cta {
                            padding: 24px;
                        }
                    }
                    .cta__background-blur {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        backdrop-filter: blur(4px);
                        background: rgba(255, 255, 255, 0.8);
                        box-shadow: var(--box-shadow);
                    }
                    .cta__content {
                        position: relative;
                    }
                    .cta__content--inner {
                        padding: 40px 16px;
                        position: relative;
                    }
                    .cta__str-logo {
                        position: relative;
                        height: 32px;
                        max-width: 200px;
                        margin: 0 auto 16px auto;
                    }
                    .cta__title {
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    .cta__copy {
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                `}
            </style>
        </div>
    );
};
export default VeteranCta;

const ButtonStyles = styled.div`
    button,
    a {
        background: var(--secondary-300);
        min-width: 100%;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        button,
        a {
            min-width: fit-content;
        }
    }
    button:hover,
    a:hover {
        background: var(--secondary-400);
    }
`;
