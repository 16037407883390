import CallTrackingNumber from 'aac-components/components/CallTrackingNumber';
import {useContext} from 'react';
import AppContext from '../AppContext';

export const CallTrackingNumberWrapper = (props) => {
    const {callTrackingNumber} = useContext(AppContext);

    return (
        <CallTrackingNumber
            {...props}
            styles={props?.style}
            callTrackingNumber={callTrackingNumber}
        />
    );
};
export default CallTrackingNumberWrapper;
