import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {zIndex} from 'aac-components/utils/helpers';
import {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {shortcodes} from '../../lib/shortcodes';

const Drawer = ({title = '', children = ''}) => {
    const [isExpanded, toggleExpand] = useState(false);

    // disable modals when drawer is open
    useEffect(() => {
        if (isExpanded) {
            document.body.classList.add('active-modal');
        } else {
            document.body.classList.remove('active-modal');
        }
    }, [isExpanded]);

    return (
        <div className="content-drawer">
            <div
                className="content-drawer__title"
                onClick={() => toggleExpand((prevInputState) => !prevInputState)}>
                <div dangerouslySetInnerHTML={{__html: title}} />
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <IconChevron rotate="-90" fill="var(--primary)" />
                </div>
            </div>
            <CSSTransition
                classNames={'slide'}
                timeout={0}
                appear={true}
                in={isExpanded}
                unmountOnExit>
                <div className="content-drawer__content">
                    <div
                        className="content-drawer__content--title"
                        onClick={() => toggleExpand(false)}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconChevron rotate="90" fill="var(--primary)" />
                        </div>
                        <div>Back</div>
                    </div>
                    <div
                        className="content-drawer__content--inner"
                        dangerouslySetInnerHTML={{__html: children}}
                    />
                </div>
            </CSSTransition>
            <style jsx>{shortcodes}</style>
            <style jsx global>
                {`
                    body {
                        overflow: ${isExpanded ? 'hidden' : 'scroll'};
                    }
                `}
            </style>
            <style jsx>
                {`
                    .content-drawer {
                        height: 60px;
                        height: auto;
                        position: relative;
                        border-bottom: 1px solid var(--gray-300);
                    }
                    .content-drawer__title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 5px;
                        font-size: 16px;
                        line-height: 24px;
                        min-height: 60px;
                        cursor: pointer;
                    }
                    .content-drawer__title > h2 {
                        font-size: 20px;
                        color: var(--primary-400);
                        font-weight: normal;
                        padding: 0px 15px;
                        font-family: var(--font-copy);
                        margin: 0;
                        padding: 0;
                    }

                    .content-drawer__content {
                        display: block;
                        background: #fff;
                        position: fixed;
                        max-height: 100vh;
                        width: 100%;
                        top: 0;
                        left: 0;
                        z-index: ${zIndex('fullScreenDrawer')};
                    }
                    .content-drawer__content--inner {
                        padding: 15px 15px 60px 15px;
                        height: 100vh;
                        margin: 60px 0;
                        overflow-x: scroll;
                    }
                    .content-drawer__content--title {
                        position: absolute;
                        box-shadow: var(--box-shadow);
                        z-index: 100;
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        padding: 15px;
                        height: 60px;
                        width: 100%;
                        color: var(--primary-400);
                    }

                    .slide-enter {
                        transform: translateX(100%);
                    }
                    .slide-enter-done {
                        transform: translateX(0px);
                        transition: transform 200ms ease;
                    }
                    .slide-exit {
                        transform: translateX(0px);
                    }
                    .slide-exit-active {
                        transform: translateX(100%);
                        transition: transform 200ms ease;
                    }
                `}
            </style>
        </div>
    );
};
export default Drawer;
