import Image from 'next/image';

const Figure = ({image = '/', position = '', alt = '', children}) => {
    return (
        <>
            <figure>
                <Image
                    src={image}
                    width={0}
                    height={0}
                    sizes={position ? '50vw' : '100vw'}
                    alt={alt || 'American Addiction Centers Photo'}
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px',
                        overflow: 'hidden',
                    }}
                />
                {children && <figcaption dangerouslySetInnerHTML={{__html: children}} />}
            </figure>
            <style jsx>{`
                figure {
                    padding: ${position === 'left'
                        ? '0 24px 24px 0'
                        : position === 'right'
                        ? '0 0 24px 24px'
                        : '0'};
                }
                figcaption {
                    margin-top: 8px;
                    font-size: 14px;
                }
            `}</style>
        </>
    );
};
export default Figure;
