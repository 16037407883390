import {BREAKPOINT} from 'aac-components/utils/styles';
import Image from 'next/image';
import {useContext, useEffect, useRef, useState} from 'react';
import CallrailLink from '../CallrailLink';
import IconCheckCircle from 'aac-components/components/Icons/Basic/IconCheckCircle';
import AppContext from '../AppContext';

const StateFundedVsPrivateCta = () => {
    const [activeCard, setActiveCard] = useState(null);
    const {isMobile} = useContext(AppContext);

    const ref = useRef();

    useEffect(() => {
        if (activeCard !== null && !!ref && isMobile) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [activeCard]);

    return (
        <div className="cta__state-funded-vs-private">
            <div className="cta__logo">
                <Image
                    src="https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/aac-lockup-horizontal-color-v2.svg"
                    width={0}
                    height={0}
                    alt="American Addiction Centers"
                    style={{width: '100%', height: '48px'}}
                />
            </div>
            <div className="cta__title">State-Funded vs Private Rehab Programs</div>
            <p>Select a topic to compare:</p>
            <div className="cta__cards">
                {cardOptions.map((card, index) => {
                    return (
                        <div
                            key={card?.image}
                            className={`cta__card ${
                                activeCard === index ? 'active' : ''
                            }`}
                            onClick={() => setActiveCard(index)}
                            title={card?.title}>
                            <div className="cta__card--image">
                                {activeCard === index ? (
                                    <IconCheckCircle
                                        fill="var(--focus)"
                                        width="28"
                                        height="28"
                                    />
                                ) : (
                                    <Image
                                        src={card?.image}
                                        alt={card?.title}
                                        width="0"
                                        height="0"
                                        sizes="64px"
                                        style={{width: '64px', height: 'auto'}}
                                    />
                                )}
                            </div>
                            <div className="cta__card--title">{card?.title}</div>
                        </div>
                    );
                })}
            </div>
            {activeCard !== null && (
                <div className="cta__info" ref={ref}>
                    <div className="cta__info--state-funded">
                        <div className="cta__info--title">State-Funded Rehab</div>
                        <div>{cardOptions?.[activeCard]?.state_funded()}</div>
                    </div>
                    <div className="cta__info--private">
                        <div className="cta__info--title">Private Rehab</div>
                        <div>{cardOptions?.[activeCard]?.private()}</div>
                    </div>
                </div>
            )}
            <style jsx>
                {`
                    .cta__state-funded-vs-private {
                        margin: 48px auto;
                        min-width: 100%;
                    }
                    .cta__logo {
                        position: relative;
                        margin-bottom: 16px;
                    }
                    .cta__title {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 40px;
                        text-align: center;
                    }
                    p {
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    .cta__cards {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 16px;
                        padding: 0 15px;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta__cards {
                            grid-template-columns: repeat(4, 1fr);
                            padding: 0 5px;
                        }
                    }
                    .cta__card {
                        padding: 16px;
                        border-radius: 8px;
                        height: 160px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border: 2px solid #fff;
                        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
                            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                    }
                    .cta__card.active {
                        background: var(--secondary-100);
                        border: 2px solid var(--focus);
                        transition: all 0.5s;
                    }
                    .cta__card.active .cta__card--title {
                        color: var(--focus);
                    }
                    .cta__card--image {
                        margin-bottom: 16px;
                        width: 64px;
                        height: 64px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .cta__card--title {
                        text-align: center;
                    }
                    .cta__info {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                        scroll-margin: 200px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta__info {
                            grid-template-columns: 1fr 1fr;
                            grid-gap: 16px;
                        }
                    }
                    .cta__info > div {
                        padding: 24px;
                        border-radius: 8px;
                    }
                    .cta__info--title {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 24px;
                        text-align: center;
                    }
                    .cta__info--state-funded {
                        background: var(--tertiary-200);
                    }
                    .cta__info--private {
                        background: var(--interactive-100);
                    }
                `}
            </style>
        </div>
    );
};
export default StateFundedVsPrivateCta;

const cardOptions = [
    {
        image: '/static/state-funded-vs-private-cta/who_qualifies.png',
        title: 'Who Qualifies',
        state_funded: () => (
            <p>
                The qualifications for entering a state-funded rehab center will vary from
                state to state. One of the most common requirements is that you fall
                within a certain income bracket and do not have private insurance.
            </p>
        ),
        private: () => (
            <p>
                Some rehabs specialize in a specific type of substance use disorder (e.g.,
                alcohol or opioids), others are men-only or women-only facilities, and
                some have additional eligibility stipulations (e.g., age). However, the
                vast majority of private facilities are open to anyone that can pay for
                treatment with insurance or private payment forms such as cash, credit,
                loans, etc. To determine how much you’d pay out of pocket for treatment at
                an American Addiction Centers (AAC) facility,{' '}
                <a
                    onClick={(e) => window && window?.AAC?.openSlideInSsvob(e)}
                    title="Verify Your Insurance Online"
                    style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: 'var(--interactive)',
                    }}>
                    verify your insurance online
                </a>
                .
            </p>
        ),
    },
    {
        image: '/static/state-funded-vs-private-cta/how_to_find.png',
        title: 'How to Find',
        state_funded: () => (
            <p>
                A good first step is to contact your healthcare provider, who may be able
                to refer you to nearby state-funded treatment programs. Or visit{' '}
                <a
                    href="https://findtreatment.gov/"
                    target="_blank"
                    rel="noreferrer nofollow"
                    title="FindTreatment.gov">
                    FindTreatment.gov
                </a>
                , which allows you to search for facilities via location, types of payment
                and funding accepted, and more.
            </p>
        ),
        private: () => (
            <p>
                Literally thousands of private rehab facilities are scattered across the
                United States. Among them, American Addiction Centers—a leading provider
                of evidence-based addiction and mental health treatment—offers{' '}
                <a href="/treatment-centers" title="multiple locations nationwide">
                    multiple locations nationwide
                </a>
                .
            </p>
        ),
    },
    {
        image: '/static/state-funded-vs-private-cta/admission.png',
        title: 'Admission',
        state_funded: () => (
            <p>
                Given the high demand for state-funded substance use disorder treatment,
                many have a waiting list, and admission may be based on availability and
                need.
            </p>
        ),
        private: () => (
            <p>
                While admissions at in-patient facilities can be limited by bed
                availability, many rehabs including those owned by American Addiction
                Centers can often offer same-day admissions. Contact an admissions
                navigator 24/7 via AAC’s free confidential helpline <CallrailLink /> to
                learn how to take your first steps toward recovery today.
            </p>
        ),
    },
    {
        image: '/static/state-funded-vs-private-cta/costs.png',
        title: 'Costs',
        state_funded: () => (
            <p>
                State-funded programs offer dramatically reduced fees or free treatment.
                However, individuals need to meet certain eligibility criteria—often
                centered around a lack of insurance and/or an inability to pay for
                treatment.
            </p>
        ),
        private: () => (
            <p>
                The cost of rehab at private-pay facilities varies according tofactors
                such as the level and duration of care, facility amenities, location,
                insurance plan variables, and more. In addition to insurance and private
                payment, private rehabs such as American Addiction Centers may also offer{' '}
                <a href="/payment-options" title="alternative funding options">
                    alternative funding options
                </a>
                , such as sliding-fee scales, financing, grants, etc.
            </p>
        ),
    },
];
