import {alphabetizeArrayByKey} from '../../lib/utils';
import Link from 'next/link';
import {BREAKPOINT} from '../../lib/styles';

import React, {useEffect, useState} from 'react';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';

const AccordionLinks = (props) => {
    const [activeTab, setActiveTab] = useState(1);
    const [accordionLinks, setAccordionLinks] = useState({});
    const [accordionTitle, setAccordionTitle] = useState('');

    const {type} = props;

    useEffect(() => {
        const links =
            type === 'addiction'
                ? addictionLinks
                : type === 'insurance'
                ? insuranceLinks
                : null;
        const title = props.title
            ? props.title
            : type === 'addiction'
            ? 'Types of Addiction'
            : type === 'insurance'
            ? 'Insurance Providers with Addiction Coverage'
            : null;

        let sortedLinks = alphabetizeArrayByKey(links, 'name');

        setAccordionLinks({
            sectionOneLinks: sortedLinks.filter((link) => link.name[0] <= 'H'),
            sectionTwoLinks: sortedLinks.filter(
                (link) => link.name[0] >= 'I' && link.name[0] <= 'N',
            ),
            sectionThreeLinks: sortedLinks.filter((link) => link.name[0] >= 'O'),
        });

        setAccordionTitle(title);
    }, []);

    const handleTabs = (number) => {
        if (number === activeTab) {
            setActiveTab(0);
        } else {
            setActiveTab(number);
        }
    };

    const {sectionOneLinks, sectionTwoLinks, sectionThreeLinks} = accordionLinks;

    return (
        <div className="tabs">
            {accordionTitle && (
                <>
                    <span
                        className="title"
                        dangerouslySetInnerHTML={{__html: accordionTitle}}
                    />
                    <hr />
                </>
            )}
            <div className={`section-container ${activeTab === 1 ? 'active' : ''}`}>
                <div
                    className={`header ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => handleTabs(1)}>
                    <div>Popular Providers</div>
                    <div>
                        <IconChevron
                            fill={activeTab === 1 ? '#fff' : 'var(--tertiary)'}
                            rotate={activeTab === 1 ? '0' : '-90'}
                        />
                    </div>
                </div>
                <div className={`links ${activeTab === 1 ? 'active' : ''}`}>
                    <ul>
                        {Array.isArray(popularProviderLinks) &&
                            popularProviderLinks
                                .sort((a, b) => a.name.localeCompare(b.name)) //sorts alphabetically
                                .map((link) => {
                                    const {name, href} = link;
                                    return (
                                        <li key={name}>
                                            <Link href={href} legacyBehavior>
                                                <a title={name}>{name}</a>
                                            </Link>
                                        </li>
                                    );
                                })}
                    </ul>
                </div>
            </div>
            <div className={`section-container ${activeTab === 2 ? 'active' : ''}`}>
                <div
                    className={`header ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => handleTabs(2)}>
                    <div>A-H</div>
                    <div>
                        <IconChevron
                            fill={activeTab === 2 ? '#fff' : 'var(--tertiary)'}
                            rotate={activeTab === 2 ? '0' : '-90'}
                        />
                    </div>
                </div>
                <div className={`links ${activeTab === 2 ? 'active' : ''}`}>
                    <ul>
                        {Array.isArray(sectionOneLinks) &&
                            sectionOneLinks.map((link) => {
                                const {name, href} = link;
                                return (
                                    <li key={name}>
                                        <Link href={href} legacyBehavior>
                                            <a title={name}>{name}</a>
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
            <div className={`section-container ${activeTab === 3 ? 'active' : ''}`}>
                <div
                    className={`header ${activeTab === 3 && 'active'}`}
                    onClick={() => handleTabs(3)}>
                    <div>I-N</div>
                    <div>
                        <IconChevron
                            fill={activeTab === 3 ? '#fff' : 'var(--tertiary)'}
                            rotate={activeTab === 3 ? '0' : '-90'}
                        />
                    </div>
                </div>
                <div className={`links ${activeTab === 3 ? 'active' : ''}`}>
                    <ul>
                        {Array.isArray(sectionTwoLinks) &&
                            sectionTwoLinks.map((link) => {
                                const {name, href} = link;
                                return (
                                    <li key={name}>
                                        <Link href={href} legacyBehavior>
                                            <a title={name}>{name}</a>
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
            <div className={`section-container ${activeTab === 4 ? 'active' : ''}`}>
                <div
                    className={`header ${activeTab === 4 ? 'active' : ''}`}
                    onClick={() => handleTabs(4)}>
                    <div>O-Z</div>
                    <div>
                        <IconChevron
                            fill={activeTab === 4 ? '#fff' : 'var(--tertiary)'}
                            rotate={activeTab === 4 ? '0' : '-90'}
                        />
                    </div>
                </div>
                <div className={`links ${activeTab === 4 ? 'active' : ''}`}>
                    <ul>
                        {Array.isArray(sectionThreeLinks) &&
                            sectionThreeLinks.map((link) => {
                                const {name, href} = link;
                                return (
                                    <li key={name}>
                                        <Link href={href} legacyBehavior>
                                            <a title={name}>{name}</a>
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
            <style jsx>
                {`
                    .tabs {
                        margin: 60px 0 30px 0;
                    }
                    hr {
                        margin: 5px 0 28px 0;
                        border-bottom: 1px solid #ccc;
                    }
                    .title {
                        font-weight: normal;
                        margin: 5px 0;
                        text-align: left;
                        font-size: 20px;
                        border-top: none;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .title {
                            text-align: left;
                            padding: 0;
                        }
                    }
                    .section-container {
                        border-radius: 6px;
                        margin-bottom: 0;
                        box-shadow: var(--box-shadow);
                    }
                    .section-container.active {
                        margin: 10px 0;
                    }
                    .header {
                        background: #fff;
                        color: var(--tertiary);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 40px;
                        font-size: 18px;
                        font-weight: bold;
                        border: 0.5px solid rgba(000, 000, 000, 0.05);
                        border-radius: 0;
                    }
                    .header.active:first-child {
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                    }
                    .header.active {
                        background: var(--tertiary);
                        color: #fff;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                        border: 0.5px solid var(--tertiary);
                    }
                    .header:hover {
                        cursor: pointer;
                        transition: all 0.25s;
                        border: 0.5px solid var(--tertiary);
                    }

                    .links {
                        max-width: 100%;
                        background: #fff;
                        transition: all 0.25s;
                        display: block;
                        padding: 0 40px;
                        border: 0px solid #fff;
                    }
                    .links.active {
                        max-height: none;
                        transition: all 0.25s;
                        padding: 20px 40px;
                        max-width: 100%;
                        display: block;
                        border: 0.5px solid rgba(000, 000, 000, 0.05);
                    }
                    .links ul {
                        max-height: 0;
                        list-style: none;
                        transition: all 0.15s;
                        max-width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding: 0;
                    }

                    .links.active ul {
                        max-height: none;
                        width: 100%;
                        column-count: 1;
                        transition: all 0.15s;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .links.active ul {
                            column-count: 3;
                        }
                    }

                    li {
                        font-size: 16px;
                        margin: 8px 0;
                        font-weight: bold;
                        padding-right: 10px;
                        display: inline-block;
                        width: 100%;
                    }
                    li a {
                        color: var(--tertiary);
                    }
                `}
            </style>
        </div>
    );
};

export default AccordionLinks;

export const popularProviderLinks = [
    {
        name: 'Aetna',
        href: '/insurance-coverage/aetna',
    },
    {
        name: 'Anthem',
        href: '/insurance-coverage/anthem',
    },
    {
        name: 'Cigna',
        href: '/insurance-coverage/cigna-insurance',
    },
    {
        name: 'United Healthcare',
        href: '/insurance-coverage/unitedhealth-group',
    },
    {
        name: 'Tufts',
        href: '/insurance-coverage/tufts-health-plan',
    },
    {
        name: 'Magellan',
        href: '/insurance-coverage/magellan-insurance',
    },
    {
        name: 'Medicaid',
        href: '/insurance-coverage/using-medicaid-to-pay-for-rehab',
    },
    {
        name: 'Blue Cross Blue Shield',
        href: '/insurance-coverage/blue-cross-blue-shield-association',
    },
    {
        name: 'Ambetter',
        href: '/insurance-coverage/ambetter',
    },
    {
        name: 'Veterans Affairs (VA) Benefits',
        href: '/insurance-coverage/va-benefits',
    },
];

export const insuranceLinks = [
    {
        name: 'Aetna',
        href: '/insurance-coverage/aetna',
    },
    {
        name: 'Amerigroup',
        href: '/insurance-coverage/amerigroup',
    },
    {
        name: 'Anthem',
        href: '/insurance-coverage/anthem',
    },
    {
        name: 'Blue Cross Blue Shield',
        href: '/insurance-coverage/blue-cross-blue-shield-association',
    },
    {
        name: 'Sierra Health',
        href: '/insurance-coverage/sierra-health-and-life-insurance',
    },
    {
        name: 'Veterans Choice Program',
        href: '/insurance-coverage/veterans-choice-program',
    },
    {
        name: 'Amerihealth',
        href: '/insurance-coverage/amerihealth-insurance',
    },
    {
        name: 'Avmed',
        href: '/insurance-coverage/avmed-insurance',
    },
    {
        name: 'Carelon Behavioral Health Options',
        href: '/insurance-coverage/carelon-behavioral-health',
    },
    {
        name: 'Cigna',
        href: '/insurance-coverage/cigna-insurance',
    },
    {
        name: 'Connecticare',
        href: '/insurance-coverage/connecticare-insurance',
    },
    {
        name: 'Geisinger',
        href: '/insurance-coverage/geisinger-insurance',
    },
    {
        name: 'Emblem Health',
        href: '/insurance-coverage/emblemhealth-insurance',
    },
    {
        name: 'Harvard Pilgrim',
        href: '/insurance-coverage/harvard-pilgrim-insurance',
    },
    {
        name: 'Health Plan of Nevada',
        href: '/insurance-coverage/health-plan-of-nevada',
    },
    {
        name: 'Magnacare',
        href: '/insurance-coverage/magnacare-insurance',
    },
    {
        name: 'Oxford Health',
        href: '/insurance-coverage/oxford-health-insurance',
    },
    {
        name: 'Behavioral Healthcare',
        href: '/insurance-coverage/behavioral-healthcare-options-bho',
    },
    {
        name: 'Humana',
        href: '/insurance-coverage/humana',
    },
    {
        name: 'Kaiser Permanente',
        href: '/insurance-coverage/kaiser-permanente',
    },
    {
        name: 'Providence Health Plan',
        href: '/insurance-coverage/providence-health-plan',
    },
    {
        name: 'State Farm',
        href: '/insurance-coverage/state-farm',
    },
    {
        name: 'United Healthcare',
        href: '/insurance-coverage/unitedhealth-group',
    },
    {
        name: 'Kemper Direct',
        href: '/insurance-coverage/kemper-direct',
    },
    {
        name: 'General Information',
        href: '/insurance-coverage',
    },
    {
        name: 'Drug Counseling',
        href: '/insurance-coverage/drug-counseling',
    },
    {
        name: 'Outpatient',
        href: '/insurance-coverage/outpatient',
    },
    {
        name: 'Medicaid',
        href: '/insurance-coverage/using-medicaid-to-pay-for-rehab',
    },
    {
        name: 'Medicare',
        href: '/rehab-guide/medicare',
    },
    {
        name: 'Veterans Affairs (VA) Benefits',
        href: '/insurance-coverage/va-benefits',
    },
    {
        name: 'Molina Healthcare',
        href: '/insurance-coverage/molina-healthcare',
    },
    {
        name: 'ComPsych',
        href: '/insurance-coverage/compsych',
    },
    {
        name: 'Managed Health Network (MHN)',
        href: '/insurance-coverage/managed-health-network-mhn',
    },
    {
        name: 'HMO Insurance',
        href: '/insurance-coverage/hmo',
    },
    {
        name: 'PPO Insurance',
        href: '/insurance-coverage/ppo',
    },
    {
        name: 'Veterans Affairs (VA) Disability',
        href: '/veterans/va-disability',
    },
    {
        name: 'VA Community Care Network',
        href: '/veterans/aac-community-care-provider',
    },
    {
        name: 'Florida Blue (BCBS Florida)',
        href: '/insurance-coverage/blue-cross-blue-shield-florida',
    },
    {
        name: 'TRICARE',
        href: '/insurance-coverage/tricare',
    },
    {
        name: 'First Health',
        href: '/insurance-coverage/first-health',
    },
    {
        name: 'Magellan',
        href: '/insurance-coverage/magellan-insurance',
    },
    {
        name: 'Meritain',
        href: '/insurance-coverage/meritain-insurance',
    },
    {
        name: 'Rocky Mountain HMO',
        href: '/insurance-coverage/rocky-mountain-hmo',
    },
    {
        name: 'Tufts',
        href: '/insurance-coverage/tufts-health-plan',
    },
    {
        name: 'UPMC',
        href: '/insurance-coverage/upmc-insurance',
    },
    {
        name: 'Zelis',
        href: '/insurance-coverage/zelis',
    },
    {
        name: '1199 SEIU Funds',
        href: '/insurance-coverage/1199seiu-funds',
    },
    {
        name: 'Suboxone Coverage',
        href: '/insurance-coverage/suboxone',
    },
    {
        name: 'Ambetter',
        href: '/insurance-coverage/ambetter',
    },
    {
        name: 'Empire Blue Cross Blue Shield',
        href: '/insurance-coverage/empire-blue-cross-blue-shield',
    },
    {
        name: 'Seminole Tribe',
        href: '/insurance-coverage/seminole-tribe',
    },
];

export const addictionLinks = [
    {
        name: 'Adderall',
        href: '/adderall/long-term-effects',
    },
    {
        name: 'Alcohol',
        href: '/alcoholism-treatment/stages',
    },
    {
        name: 'Ambien',
        href: '/ambien-treatment/long-term',
    },
    {
        name: 'Amphetamine',
        href: '/amphetamine',
    },
    {
        name: 'Ativan',
        href: '/ativan-treatment',
    },
    {
        name: 'Benzodiazepine',
        href: '/benzodiazepine',
    },
    {
        name: 'Clonazepam',
        href: '/withdrawal-timelines-treatments/clonazepam',
    },
    {
        name: 'Cocaine',
        href: '/cocaine-treatment/signs',
    },
    {
        name: 'Codeine',
        href: '/codeine-addiction',
    },
    {
        name: 'Crack',
        href: '/crack-addiction',
    },
    {
        name: 'DMT',
        href: '/psychedelics/side-effects-of-dmt',
    },
    {
        name: 'Fentanyl',
        href: '/fentanyl-treatment',
    },
    {
        name: 'Heroin',
        href: '/heroin-treatment/signs',
    },
    {
        name: 'Ibogaine',
        href: '/meth-treatment/success-rate-for-ibogaine',
    },
    {
        name: 'Ketamine',
        href: '/ketamine-abuse',
    },
    {
        name: 'Kratom',
        href: '/kratom',
    },
    {
        name: 'Marijuana',
        href: '/marijuana-rehab',
    },
    {
        name: 'Meloxicam',
        href: '/meloxicam-abuse',
    },
    {
        name: 'Meth',
        href: '/meth-treatment',
    },
    {
        name: 'Methadone',
        href: '/methadone-addiction',
    },
    {
        name: 'Modafinil',
        href: '/adderall/vs-modafinil',
    },
    {
        name: 'Morphine',
        href: '/morphine-treatment',
    },
    {
        name: 'Neurontin',
        href: '/neurontin-abuse',
    },
    {
        name: 'Opiate',
        href: '/opiates',
    },
    {
        name: 'Oxycontin',
        href: '/oxycontin-treatment',
    },
    {
        name: 'Percocet',
        href: '/percocet-treatment',
    },
    {
        name: 'Phenibut',
        href: '/phenibut',
    },
    {
        name: 'Prescription Drugs',
        href: '/prescription-drugs',
    },
    {
        name: 'Salvia',
        href: '/psychedelics/salvia',
    },
    {
        name: 'Suboxone',
        href: '/suboxone',
    },
    {
        name: 'Tramadol',
        href: '/tramadol-abuse',
    },
    {
        name: 'Trazodone',
        href: '/trazodone-abuse',
    },
    {
        name: 'Xanax',
        href: '/xanax-treatment',
    },
    {
        name: 'Stimulants',
        href: '/stimulant-drugs',
    },
    {
        name: 'Narcotics',
        href: '/the-big-list-of-narcotic-drugs',
    },
    {
        name: 'Valium',
        href: '/valium-treatment',
    },
    {
        name: 'Vicodin',
        href: '/withdrawal-timelines-treatments/vicodin',
    },
    {
        name: 'Hydrocodone',
        href: '/withdrawal-timelines-treatments/hydrocodone',
    },
];
