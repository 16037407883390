import Button from 'aac-components/components/Button';
import {BREAKPOINT} from 'aac-components/utils/styles';

const CtaShortcode = (props) => {
    return (
        <div className="cta-shortcode">
            <div
                className="cta-shortcode__copy text-xl"
                dangerouslySetInnerHTML={{
                    __html:
                        props?.children ||
                        'Check to see if your insurance is in network with American Addiction Centers.',
                }}
            />
            <div className="cta-shortcode__button">
                <Button
                    href={props?.link || '/verify-insurance'}
                    style={{fontWeight: 'normal', width: '100%'}}>
                    {props?.button || 'Does your insurance cover treatment?'}
                </Button>
            </div>
            <style jsx>
                {`
                    .cta-shortcode {
                        border: 4px solid var(--secondary-100);
                        padding: 24px 16px;
                        border-radius: 8px;
                        margin: 16px 0;
                    }
                    .cta-shortcode__copy {
                        margin-bottom: 24px;
                        text-align: center;
                    }
                    .cta-shortcode__button {
                        width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta-shortcode__button {
                            max-width: fit-content;
                            margin: 0 auto;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default CtaShortcode;
