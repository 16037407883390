import Button from 'aac-components/components/Button';
import AcfTooltip from '../Tooltips/AcfTooltip';
import {useContext, useEffect} from 'react';
import AppContext from '../AppContext';

const CallrailButton = ({style, color, text, theme = 'primary', showTooltip = true}) => {
    const {callTrackingNumber} = useContext(AppContext);

    useEffect(() => {
        if (typeof window !== 'undefined' && window?.CallTrk) {
            window.CallTrk.swap();
        }
    }, []);
    return (
        <div style={{...style}} className="button-phone">
            <Button
                href={callTrackingNumber?.href}
                theme={theme}
                style={{minWidth: '100%', fontWeight: 'normal'}}>
                <div className="button__copy">
                    {text ? (
                        <div dangerouslySetInnerHTML={{__html: text}} />
                    ) : (
                        <>Call {callTrackingNumber?.display}</>
                    )}
                </div>
            </Button>
            {showTooltip && (
                <div className="tooltip">
                    <AcfTooltip type="phone" color={color} />
                </div>
            )}
            <style jsx>
                {`
                    .button-phone {
                        text-align: center;
                        width: fit-content;
                        max-width: 100%;
                    }
                    .tooltip {
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        font-weight: bold;
                        margin: 4px auto 0 auto;
                    }
                    .button__copy {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 5px;
                    }
                    .button__copy > div:nth-child(2) {
                        min-width: 114px;
                    }
                `}
            </style>
        </div>
    );
};
export default CallrailButton;
